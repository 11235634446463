<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>

      <v-form ref="form" lazy-validation v-on:submit.prevent>
        <v-card-title class="justify-center">
          <span class="headline">{{
            isEdit ? this.$t('adminCourses.edit') : this.$t('adminCourses.new')
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="data.title"
                  :label="$t('adminCourses.title')"
                  :rules="titleRules"
                  :readonly="!isAdmin"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-1">
                <v-textarea
                  v-model="data.description"
                  :label="$t('adminCourses.moduleDescription')"
                  :rules="descriptionRules"
                  :readonly="!isAdmin"
                  rows="2"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  v-model="data.order"
                  :label="$t('adminCourses.order')"
                  :rules="titleRules"
                  :readonly="!isAdmin"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
              <v-radio-group
                  v-if="!this.isEdit"
                  row
                  :readonly="!isAdmin"
                  :label="$t('adminCourses.courseLanguage') + ': '"
                  v-model="data.language"
                  :rules="[(v) => !!v || this.$t('required.field')]"
                  class="mb-6"
                >
                  <v-radio
                    v-for="(lang, index) in languages"
                    :key="index"
                    :label="lang.label"
                    :value="lang.value"
                  ></v-radio>
                </v-radio-group>
              </v-col>
                <v-col cols="12">
              <v-radio-group
                  :readonly="!isAdmin"
                  v-if="this.$route.query.type === 'lecture'"
                  row
                  :label="this.$t('adminCourses.fileType') + ': '"
                  v-model="data.type"
                  :rules="[(v) => !!v || this.$t('required.field')]"
                  class="mb-6"
                >
                  <v-radio
                    v-for="(type, index) in types"
                    :key="index"
                    :label="type.label"
                    :value="type.value"
                  ></v-radio>
                </v-radio-group>
                </v-col>

                <v-col v-if="data.content_url && this.$route.query.type === 'lecture'" cols="12">
                  <v-subheader>{{ $t('adminCourses.currentFile') }}: </v-subheader>
                  <a :href="'https://bact-back.usol.ca/' + data.content_url">{{ 'https://bact-back.usol.ca/' + data.content_url }}</a>
                </v-col>

                <v-col v-if="this.$route.query.type === 'lecture'" cols="12">
                <v-file-input
                  v-if="isAdmin"
                  :label="this.isEdit ? $t('adminCourses.replaceFile') : $t('adminCourses.newFile')"
                  v-model="data.file"
                  :rules="this.isEdit ? [] : [(v) => !!v || this.$t('required.field')]"
                  counter
                  show-size
                >
                </v-file-input>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <base-button
            :options="{ color: this.YColor }"
            text
            @click="close"
          >
            {{ this.$t('form.cancel') }}
          </base-button>
          <base-button @click="save" :options="{ color: this.primaryColor }" v-if="isAdmin"> {{ $t('form.save') }} </base-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../../../core/Base/Buttons/BaseButton";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "HandelQuestion",
  props: {
    isEdit: Boolean,
    moduleId: Number
  },
  data() {
    return {
      titleRules: [(v) => !!v || this.$t('required.field')],
      descriptionRules: [(v) => !!v || this.$t('required.field')],
      thumbnailRules: [(v) => !!v || this.$t('required.field')],
      data: {},
      dialog: true,
      languages: [
        { label: this.$t("langs.arabic"), value: "ar" },
        { label: this.$t("langs.english"), value: "en" },
      ],
      types: [
        { label: 'Video', value: "video" },
        { label: 'PDF', value: "sheet" },
      ],
    };
  },
  created () {
    if (this.isEdit) {
      this.fetchSingleData({ id: this.moduleId, type: this.$route.query.type }).then(() => {
        this.data = this.getSingleData;
      })
    }
  }, 
  methods: {
    ...mapActions("Courses/Modules", ["fetchSingleData", "addData", "updateData", "deleteData"]),

    close() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      this.$emit("handleCancel");
    },

    save() {
      if (!this.$refs.form.validate()) return;
      console.log(this.data)
      if (this.isEdit) {
        this.data.createdAt = undefined; // because it is not allowed
        this.data.updatedAt = undefined; // because it is not allowed
        this.data.content_url = undefined; // because it is not allowed
        this.updateData({ data: this.data, type: this.$route.query.type }).then(() => {
          this.$emit('handleClose')
        })
      } else {
        if (this.$route.query.type === 'module') {
          this.data.course_id = this.$route.params.id;
        } else if (this.$route.query.type === 'lecture') {
          this.data.module_id = this.$route.params.id;
        }
        this.addData({ data: this.data, type: this.$route.query.type }).then(() => {
          this.$emit('handleClose');
        })
      }
    },
  },
  computed: {
    ...mapGetters("Courses/Modules", ["getSingleData"]),
    isAdmin () {
      return (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal')
    },
  },

  components: { BaseButton },
};
</script>
