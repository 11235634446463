<template>
  <v-row>
    <notifications
      group="public"
      position="bottom center"
      :style="{ marginBottom: '2px' }"
    />
    <modules-table v-if="isModule" @newData="newData = true" :data="{
      headerOptions : { withAddNew : true , withSearch : false , withExport : false},
      tableHeader : headers ,
      tableItems : getData.modules,
      extraOptions : {
        footerOptions : {
          itemsPerPageText : this.$t('table.rowNumber')
        },
      },
      withActions : true
    }"
    >
      <h1 slot="tableTitle" class="font-weight-bold" :style="{'color' : this.primaryColor}">
        <base-arrow-back-icon @click="$router.go(-1)">{{ this.rtl ? rightArrowIcon : leftArrowIcon }}</base-arrow-back-icon>
        
        {{ this.isModule ? $t('adminCourses.modules') + ': ' + this.getData.course_title : $t('adminCourses.lectures') + ': ' + this.$route.query.moduleTitle }}
      </h1>
      <template #default="{data}">

        <div class="text-center">
          <v-menu offset-y open-on-hover min-width="150">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="primaryColor"
                icon
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon large>{{ settingsIcon }}</v-icon>
              </v-btn>
            </template>
            <v-list>

              <router-link v-if="isModule" :to="{name : 'editCourseModule' , params : {id : data.id} , query : { type : 'lecture', moduleTitle : data.title }}">
              <v-list-item >
                <v-list-item-title>{{ $t('adminCourses.lectures') }}</v-list-item-title>
              </v-list-item>
              </router-link>

              <router-link v-if="isModule" :to="{name : 'moduleQuestions' , params : {id : data.id} }">
              <v-list-item >
                <v-list-item-title>{{ $t('adminCourses.q&a') }}</v-list-item-title>
              </v-list-item>
              </router-link>

              <v-list-item @click="isEdit = true; selectedModule = data.id">
                <v-list-item-title>{{ isAdmin ? 'تعديل' : 'تفاصيل'}}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isAdmin" @click="deleteConfirm = data.id">
                <v-list-item-title>حذف</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
        </div>
        
          <!-- <v-btn @click="isEdit = true; selectedModule = data.id" text icon color="secondary lighten-2">
            <v-icon>{{ isAdmin ? editIcon : eyeIcon}}</v-icon>
          </v-btn>

          <v-btn v-if="isAdmin" text icon color="secondary lighten-2"
            @click="deleteConfirm = data.id"
          >
            <v-icon color="red">{{ deleteIcon }}</v-icon>
          </v-btn>

          <router-link v-if="isModule" :to="{name : 'editCourseModule' , params : {id : data.id} , query : { type : 'lecture', moduleTitle : data.title }}">
            <v-btn small :color="primaryColor" class="ma-2">
              {{ $t('adminCourses.lectures') }}
            </v-btn>
          </router-link>
          
          <router-link v-if="isModule" :to="{name : 'moduleQuestions' , params : {id : data.id} }">
            <v-btn small :color="YColor" class="ma-2">
              {{ $t('adminCourses.q&a') }}
            </v-btn>
          </router-link> -->
        
      </template>
    </modules-table>
    <lectures-table v-else @newData="newData = true" :data="{
      headerOptions : { withAddNew : true , withSearch : false , withExport : false},
      tableHeader : headers ,
      tableItems : getData.lectures ,
      extraOptions : {
        footerOptions : {
          itemsPerPageText : this.$t('table.rowNumber')
        },
      },
      withActions : true
    }"
    >
      <h1 slot="tableTitle" class="font-weight-bold" :style="{'color' : this.primaryColor}">
        <base-arrow-back-icon @click="$router.go(-1)">{{ this.rtl ? rightArrowIcon : leftArrowIcon }}</base-arrow-back-icon>
        
        {{ this.isModule ? $t('adminCourses.modules') + ': ' + this.getData.course_title : $t('adminCourses.lectures') + ': ' + this.$route.query.moduleTitle }}
      </h1>
      <template #default="{data}">
        
          <div class="text-center">
          <v-menu offset-y open-on-hover min-width="150">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="primaryColor"
                icon
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon large>{{ settingsIcon }}</v-icon>
              </v-btn>
            </template>
            <v-list>

              <router-link v-if="isModule" :to="{name : 'editCourseModule' , params : {id : data.id} , query : { type : 'lecture', moduleTitle : data.title }}">
              <v-list-item >
                <v-list-item-title>{{ $t('adminCourses.lectures') }}</v-list-item-title>
              </v-list-item>
              </router-link>

              <router-link v-if="isModule" :to="{name : 'moduleQuestions' , params : {id : data.id} }">
              <v-list-item >
                <v-list-item-title>{{ $t('adminCourses.q&a') }}</v-list-item-title>
              </v-list-item>
              </router-link>
              
              <v-list-item @click="isEdit = true; selectedModule = data.id">
                <v-list-item-title>{{ isAdmin ? 'تعديل' : 'تفاصيل'}}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isAdmin" @click="deleteConfirm = data.id">
                <v-list-item-title>حذف</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
        </div>
        
      </template>
    </lectures-table>
    <module-dialog
      v-if="isEdit || newData"
      v-bind="{
        isEdit: isEdit,
        moduleId: selectedModule
      }"
      @handleCancel="handleCancel"
      @handleClose="handleClose"
    ></module-dialog>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="handleClose"
      @handleCancel="handleCancel"
      @handelRequest="handleDelete"
    ></confirm-delete>
  </v-row>
</template>

<script>
import ModulesTable from "./Components/ModulesTable";
import LecturesTable from "./Components/LecturesTable";
import ModuleDialog from "./Components/ModuleDialog";
import ConfirmDelete from "../../Components/confirmDelete";
import BaseArrowBackIcon from "../../../../../../core/Base/Arrows/BaseArrowBackIcon";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Modules",

  components: { 
    ModulesTable, 
    ModuleDialog, 
    ConfirmDelete,
    BaseArrowBackIcon,
    LecturesTable
  },

  data() {
    return {
      selectedModule: null,
      isEdit: false,
      newData: false,
      headers: [
        { text: this.$t('adminCourses.title'), value: "title" },
        { text: this.$t('adminCourses.moduleDescription'), value: "description" },
        { text: "", value: "actions", align: "center" },
      ],
      add_Traning: false,
      deleteConfirm: -1,
    };
  },

  created () {
    console.log(this.isAdmin)
    this.init()
  },

  watch: {
    '$route.params.id': function () {
      this.init()
    }
  },

  computed: {
    ...mapGetters("Courses/Modules", ["getData"]),

    ...mapState(["rtl"]), // to decide the direction of the arrow

    isAdmin () {
      let admin = (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal');
      let notDenied = localStorage.getItem('courseStatus') === 'notDenied' ;
      return (admin && notDenied)
    },

    isModule () {
      return this.$route.query.type==='module'
    }
  },

  methods: {
    ...mapActions("Courses/Modules", ["fetchData", "deleteData"]),
    
    init () {
      this.fetchData({ id: this.$route.params.id, type: this.$route.query.type })
    },

    handleDelete (payload) {
      this.deleteData({ id: payload.id, type: this.$route.query.type }).then(() => {
        this.handleClose();
      })
    },

    handleCancel () {
      this.isEdit = false;
      this.selectedModule = 0;
      this.newData = false;
      this.deleteConfirm = -1;
    },

    handleClose () {
      this.isEdit = false;
      this.selectedModule = 0;
      this.newData = false;
      this.deleteConfirm = -1;
      this.fetchData({ id: this.$route.params.id, type: this.$route.query.type })
    },
    
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
