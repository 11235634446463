<template>
  <v-col>
    <v-row class="uk-container uk-margin-large-top">
      <v-col cols="12">
        <slot name="tableTitle"></slot>
      </v-col>
      <v-col cols="12">
        <table-actions-nav @newData="$emit('newData')" :data="data" :options="data.headerOptions"></table-actions-nav>
      </v-col>
    </v-row>
    <v-row class="uk-margin-small-top">
      <template>
        <v-data-table
          :headers="data.tableHeader"
          :items="data.tableItems"
          :items-per-page="5"
          class="elevation-1 w-100"
          :footer-props="data.extraOptions.footerOptions"
          :loading="loadingInstructors"
          mobile-breakpoint="700"
          @click:row="openLect"
        >
          <template v-slot:item.actions="{item}">
            <v-row
              align="center"
              justify="center"
            >
              <slot  :data="item"></slot>
            </v-row>
          </template>
        </v-data-table>
      </template>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TableActionsNav from "./TableActionsNav";

export default {
  name: "InstructorsTable",
  components: {TableActionsNav},
  props: {
    data: Object,
  },
  methods: {
    ...mapActions("Courses/Modules", ["fetchSingleData"]),
    search (payload) {
      this.$emit('search', {searchTerm: payload.searchTerm})
    },
    openLect (payload) {
      console.log(payload)
      this.fetchSingleData({ id: payload.id, type: 'lecture' }).then(() => {
        console.log(this.getSingleData)
        window.open('https://bact-back.usol.ca/' + this.getSingleData.content_url, '_blank');
      })
    }
  },
  computed: {
    ...mapGetters("Instructors", ["loadingInstructors"]),
    ...mapGetters("Courses/Modules", ["getSingleData"]),
  }
}
</script>

<style scoped>
.v-data-table
  /deep/
  tbody
  /deep/
  tr:hover {
    cursor: pointer;
    background: lightgray !important;
}
</style>
