<template>
  <v-row class="d-flex align-center uk-margin-auto" style="justify-content: flex-end !important">

    <v-btn @click="$emit('newData')" class="white--text ma-2" v-if="data.headerOptions.withAddNew && isAdmin" :color="this.YColor">
      <v-icon>{{this.plusIcon}}</v-icon>
      {{ $t('table.addNew') }}
    </v-btn>

    <form uk-grid lazy-validation v-on:submit.prevent @submit="$emit('search', {searchTerm: search})"  v-if="data.headerOptions.withSearch" class="uk-search uk-search-default">
      <div class="uk-width-auto uk-position-relative">
        <span class="uk-search-icon-flip" :style="{'color':this.primaryColor }" uk-search-icon></span>
        <input class="uk-search-input"  v-model="search" type="search" :placeholder="$t('navBar.search')">
      </div>
    </form>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "TableActionsNav",
  props : {
    options : Object,
    data: Object
  },
  data(){
    return {
      search : '',
    }
  },
  computed: {
    isAdmin () {
      let admin = (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal');
      let notDenied = localStorage.getItem('courseStatus') === 'notDenied' ;
      return (admin && notDenied)
    },
  }
}
</script>

<style scoped>

</style>
