var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('notifications',{style:({ marginBottom: '2px' }),attrs:{"group":"public","position":"bottom center"}}),(_vm.isModule)?_c('modules-table',{attrs:{"data":{
    headerOptions : { withAddNew : true , withSearch : false , withExport : false},
    tableHeader : _vm.headers ,
    tableItems : _vm.getData.modules,
    extraOptions : {
      footerOptions : {
        itemsPerPageText : this.$t('table.rowNumber')
      },
    },
    withActions : true
  }},on:{"newData":function($event){_vm.newData = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.primaryColor,"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,true)},[_c('v-list',[(_vm.isModule)?_c('router-link',{attrs:{"to":{name : 'editCourseModule' , params : {id : data.id} , query : { type : 'lecture', moduleTitle : data.title }}}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('adminCourses.lectures')))])],1)],1):_vm._e(),(_vm.isModule)?_c('router-link',{attrs:{"to":{name : 'moduleQuestions' , params : {id : data.id} }}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('adminCourses.q&a')))])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){_vm.isEdit = true; _vm.selectedModule = data.id}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.isAdmin ? 'تعديل' : 'تفاصيل'))])],1),(_vm.isAdmin)?_c('v-list-item',{on:{"click":function($event){_vm.deleteConfirm = data.id}}},[_c('v-list-item-title',[_vm._v("حذف")])],1):_vm._e()],1)],1)],1)]}}],null,false,434090581)},[_c('h1',{staticClass:"font-weight-bold",style:({'color' : this.primaryColor}),attrs:{"slot":"tableTitle"},slot:"tableTitle"},[_c('base-arrow-back-icon',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(this.rtl ? _vm.rightArrowIcon : _vm.leftArrowIcon))]),_vm._v(" "+_vm._s(this.isModule ? _vm.$t('adminCourses.modules') + ': ' + this.getData.course_title : _vm.$t('adminCourses.lectures') + ': ' + this.$route.query.moduleTitle)+" ")],1)]):_c('lectures-table',{attrs:{"data":{
    headerOptions : { withAddNew : true , withSearch : false , withExport : false},
    tableHeader : _vm.headers ,
    tableItems : _vm.getData.lectures ,
    extraOptions : {
      footerOptions : {
        itemsPerPageText : this.$t('table.rowNumber')
      },
    },
    withActions : true
  }},on:{"newData":function($event){_vm.newData = true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.primaryColor,"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,true)},[_c('v-list',[(_vm.isModule)?_c('router-link',{attrs:{"to":{name : 'editCourseModule' , params : {id : data.id} , query : { type : 'lecture', moduleTitle : data.title }}}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('adminCourses.lectures')))])],1)],1):_vm._e(),(_vm.isModule)?_c('router-link',{attrs:{"to":{name : 'moduleQuestions' , params : {id : data.id} }}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('adminCourses.q&a')))])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){_vm.isEdit = true; _vm.selectedModule = data.id}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.isAdmin ? 'تعديل' : 'تفاصيل'))])],1),(_vm.isAdmin)?_c('v-list-item',{on:{"click":function($event){_vm.deleteConfirm = data.id}}},[_c('v-list-item-title',[_vm._v("حذف")])],1):_vm._e()],1)],1)],1)]}}])},[_c('h1',{staticClass:"font-weight-bold",style:({'color' : this.primaryColor}),attrs:{"slot":"tableTitle"},slot:"tableTitle"},[_c('base-arrow-back-icon',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(this.rtl ? _vm.rightArrowIcon : _vm.leftArrowIcon))]),_vm._v(" "+_vm._s(this.isModule ? _vm.$t('adminCourses.modules') + ': ' + this.getData.course_title : _vm.$t('adminCourses.lectures') + ': ' + this.$route.query.moduleTitle)+" ")],1)]),(_vm.isEdit || _vm.newData)?_c('module-dialog',_vm._b({on:{"handleCancel":_vm.handleCancel,"handleClose":_vm.handleClose}},'module-dialog',{
      isEdit: _vm.isEdit,
      moduleId: _vm.selectedModule
    },false)):_vm._e(),(_vm.deleteConfirm >= 0)?_c('confirm-delete',_vm._b({on:{"handleClose":_vm.handleClose,"handleCancel":_vm.handleCancel,"handelRequest":_vm.handleDelete}},'confirm-delete',{ id: _vm.deleteConfirm, isOpen: true },false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }